import { css } from 'styled-components';

export type CssBreakpoints = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
  xxxl: number;
};

export const breakpoints: CssBreakpoints = {
  xs: 321,
  sm: 376,
  md: 540,
  lg: 992,
  xl: 1200,
  xxl: 1600,
  xxxl: 2000,
};

export const media = Object.keys(breakpoints).reduce((accumulator, label) => {
  const emSize = breakpoints[label] / 16;

  (<any>accumulator)[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      // @ts-ignore
      ${css(...(args as [any, any]))}
    }
  `;

  return accumulator;
}, {}) as any;

export default media;
