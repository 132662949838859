import { setConfiguration } from 'react-grid-system';
import { createGlobalStyle } from 'styled-components';
import normalized from 'styled-normalize';

setConfiguration({ maxScreenClass: 'xxl' } as any);

export const GlobalStyles = createGlobalStyle`
  ${normalized}

  html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    touch-action: manipulation;
  }

  body {
    ${props => props.theme.body}
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  p {
    line-height: 1.5;
  }
  
  a,
  button {
    outline: none;
    cursor: pointer;
  }
`;

export { default as media } from './media';
